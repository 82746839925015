import React, { useState } from "react"
import styled from "styled-components"
import BurgerMenu from "./burger-menu"

const Hamburger = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = e => {
    setOpen(!open)
  }

  return (
    <>
      <Burger onClick={handleOpen}>
        <div />
        <div />
        <div />
      </Burger>
      {open ? <BurgerMenu open={open} setOpen={setOpen} /> : null}
    </>
  )
}

export default Hamburger

const Burger = styled.button`
  position: relative;
  margin: 0 40px 0 auto;
  /* top: 5%; */
  /* left: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  outline: orange;

  &focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #fff;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`
