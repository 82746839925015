import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = () => {


  const data = useStaticQuery(graphql`
    query {
      sonny1: imageSharp(fluid: {originalName: { eq: "headshot-1.png" } } ) {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <ImageContainer>
      <Img fluid={data.sonny1.fluid} />
    </ImageContainer>
  )
}

export default Image

const ImageContainer = styled.div`
  width: 100%;
  height: 0;

  .gatsby-image-wrapper {
    border-radius: 100%;
  position: absolute;
  top: -212px;
  left: -52%;
  border-radius: 100%;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 36%);

  @media screen and (max-width: 1280px) {
    left: -32%;
  }

  @media screen and (max-width: 1024px) {
    left: -34%;
    width: 175px;
    height: 175px; 
  }

  @media screen and (max-width: 800px) {
      top: -372px;
      left: 0%;
    }
  }
`