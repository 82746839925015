import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = () => {

  const data = useStaticQuery(graphql`
    query {
      sonny2: imageSharp(fluid: { originalName: { eq: "headshot-2.png" } }) {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <ImageContainer>
      <Img fluid={data.sonny2.fluid} />
    </ImageContainer>
  )
}

export default Image

const ImageContainer = styled.div`
  width: 100%;
  /* height: 0; */

  .gatsby-image-wrapper {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    top: -67px;
    border-radius: 100%;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 36%);

    img {
      height: 144%;
    }
  }
`
