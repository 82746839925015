import React from "react"
import styled from "styled-components"

const Footer = () => {
  return (
    <Container>
      <div>
        <p>Join me on</p>
        <a
          href="https://www.facebook.com/somaticwellnesscoach"
          target="_blank"
          rel="noreferrer noopener"
        >
          facebook
        </a>
      </div>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  width: 100%;
  height: 400px;
  /* background: gray; */
  display: grid;
  place-items: center;

  div {
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    p {
      margin: 3px;
    }
  }

  h1 {
    margin: 0;
  }

  a {
    font-size: 1.2rem;
    font-weight: 700;
    text-decoration: none;
  }
`
