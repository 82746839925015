import React from "react"
import styled from "styled-components"
import Image from './image'

// import SEO from "../seo"

const About = () => {
  return (
    <>
      {/* <SEO title="About" /> */}
      <Section id="About">
        <Container>
          <h1>About Me</h1>
          <Image />
          <p>
            <br /><br />  <span>What you should know about me as a person and a coach/therapist</span><br /><br />
            Hi my name is Sunny Donaire. I am a transgender male born and raised
            in Tupi South Cotabato, Mindanao, Philippines, where I earned a
            Midwifery degree in 1999. I Arrived here in America in 2001, graduated from the Skyline College's Respiratory Therapy Program in
            2006, and went on to earn a BA in Interdisciplinary Studies and Masters in Psychological Studies, and trained in Somatic Psychotherapy from California Institute of Integral Studies in 2016.

            <br /> <br /><span>How I become the person I am today</span> <br /> <br /> Courage, resilience and a willingness to keep moving forward are what made me who I am today. I have had hard experiences in life. I was bullied because I am different; was told I was not very smart and wouldn’t go far in school; and, for 13 years of my life, I was in pain because I could not be the real me. I wished my life would end tragically. I was not happy even though I “thought” I should act happy all the time. I was discriminated against by a teacher in grad school because my practice was different. However, I got through all that and, with the support of friends, some family, and a partner in my life that saw me just as I was, learned to get back up and keep striving. All those challenging experiences helped me learn and get stronger. There are times when I have wanted to give up but then, despite what others say and think, I tell myself that only I am in charge of my future.

            <br /> <br />  <span>Qualities that have supported me on my journey</span> <br /> <br /> My curiosity – about life and about future possibilities; my resilience; and my courage have all supported me on my journey. I believe that we need to be curious about the possibilities for our future because if we don’t do that, we’re giving up. There's always a better version of ourselves and we need to continue moving forward in order to discoverit. Every step is hard work, but we deserve to take our place in this world because we have worked hard. I do my best to always move forward and even though I have failed many times, I still persevere. I practice somatics and believe that because of the path I have walked, I can guide and walk with someone on the path they/he/she chooses.

            <br /> <br /> <span>My greatest strength</span> <br /> <br /> I am a good observer and listener and my work with somatics has enriched that gift. I read between the lines well and often understand what was not said. Body language helps me discover and understand the unknown and my patience is almost limitless though, believe me, I am firm when necessary. I find calmness in complicated situations, and am able to process complex circumstances.

            <br /> <br /> <span>I have changed since I was a child</span> <br /> <br />  Growing up, I was insecure and I deeply cared and believed what other people thought of me. I let them drive and define who I was.  It wasn’t easy but now I understand that those experiences made me stronger, wiser, and more confident. I walk my path with dignity and integrity no matter what others may think of me.  I have respect for everyone's hardship and am happy to see him/her/they be successful. I don't judge for I have learned that nothing good comes out of it!

            <br /> <br /> <span>My somatics journey</span> <br /> <br /> I started practicing somatics when I knew I couldn’t be who I was meant to be. I started reaching out to friends who I knew would support me on this journey. Back home in the Philippines, the word somatics is not popular and is rarely heard, unlike in the US. My friends and I practiced somatics by talking to each other almost every day. We hiked and talked; we were there for each other.  One of our greatest needs is to be seen as human beings.  We show affection by hugging and we also laugh out loud at our problems.  Being in nature, being authentic and supporting ourselves and each other are some of the ways we practiced somatics. We Filipinos believe that laughter is  medicine. Not that we are not serious about life and its problems, we just instinctively know that it is important to always be able to laugh at ourselves.  Laughing helps us process our problems. We Filipinos would say "problem na nga! proproblemahin mo pa!pano mo ma solve kung aborido ka! ma buang ka lang!" This translates as: "It is what it is!  How can you solve a problem if you’re too busy stressing about solving a problem? You are just going to lose your mind!” You see, we Filipinos do practice somatics even though some of us don't know that’s what it is called.

            <br /> <br /> <span>My decision to study Somatic Psychotherapy</span> <br /> <br /> One of the reasons why I wanted to become a therapist was because I accompanied someone who has mental health issues for ten years. Having that person in my life opened up a new chapter and a window that led me to become a better version of myself. That experience made my life full, made me believe in myself, that I am a special person, a person that can carry a lot and yet still have more to give. That window allows me to understand and be able to adapt and walk in two very different cultures and makes me want to understand other cultures. I remember my therapist telling me at the end of the first visit: “Your state of mind is amazing and how you view life, taking care of your body and mind, is outstanding. You are what we need in somatics practice; you can guide so many people just by being who you are.”

            <br /> <br /> <span>My somatics practice is different</span> <br /> <br /> I am different from other coaches/therapists because even though I might be consideredyoung, I have the heart of an old soul and I can walk with you wherever you want to go. I can talk privately within four corners of the room or outside in the world where everything can be healing. If you choose, I can guide you in meditation or different theories of somatics.  And, if you are the type of person that needs less meditation and more action, you and I can garden, build something, walk in the park, or go for a hike. I can accompany you wherever you are comfortable until you are ready for me to walk along with you.

            <br /><br />In closing, I believe that every experience, whether good or challenging, has a purpose in our lives. I am grateful for all the people that supported me and my success and also for those who judged me, discriminated against me and put me down. Without you all I
            would never have undertaken this journey and become the confident, positive, upbeat, hopeful person I am today. THANK YOU!
          </p>
        </Container>
      </Section>
    </>
  )
}

export default About

const Section = styled.section`
  min-height: 100vh;
  margin: 0 auto 20px;
  padding-top: 140px;
  width: 100%;
  overflow: hidden;
`

const Container = styled.div`
  position: relative;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  h1 {
    color: #1f70f2;
    font-size: 3rem;
    margin: 0 auto 100px;
    text-align: center;
    padding: 40px 2%;
    &:after {
      content: ""; 
      position: absolute;
      background: rebeccapurple;
      /* border-radius: 100%; */
      border-bottom: 500px solid transparent;
      border-left: 240px solid #fff;
      border-right: 240px solid #fff;
      /* border-top: 3px; */
      /* clear: both; */
      /* width: 350px; */
      /* height: 350px; */
      width: 0;
      height: 0;
      top: -30px;
      left: -15%;
      z-index: -10;
      opacity: 0.4;

      @media screen and (max-width: 800px) {
        top: 320px;
        left: -47%;
        display: none; 
      }
    }
  }

  p {
    text-align: justify;
    font-weight: 500;
    /* font-size: 1.2rem; */
    letter-spacing: 1.2px;
    /* line-height: 35px; */
    padding: 40px 2%;
    margin: 0 0 80px;
    border-radius: 4px;
    background: #6556af33;

    line-height: 30px;

    span {
      font-weight: bold;
      line-height: 35px;
      padding-bottom: 40px;
    }

    @media screen and (max-width: 800px) {
      letter-spacing: 1px;
      line-height: 35px;
      padding: 40px 4%;
      margin: 0 2% 80px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 1000px;
      height: 300px;
      top: 82%;
      left: 30%;
      /* background: #ebc63433; */
      background: #f0342655;
      z-index: -10;
    }
  }
`
