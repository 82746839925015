import React from "react"
import styled from "styled-components"

// import SEO from "../seo"
import SVGBG from "../../assets/bg7-2.svg"
import Image from "./image"
// import Background from "./svg-background"

const Contact = () => (
  <>
    {/* <SEO title="Contact" /> */}
    <section
      id="Contact"
      style={{
        minHeight: `100vh`,
        paddingTop: `40px`,
        margin: `140px 4% 80px`,
      }}
    >
      <h2
        style={{
          textAlign: "center",
          margin: `100px auto 100px`,
          color: `#1f70f2`,
          fontSize: `3rem`,
        }}
      >
        Contact
      </h2>
      <Image />
      <Container>
        <div>
          <div className="card">
            <form
              action="/success"
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="contact" value="contact" />
              <div className="input-sections">
                <input
                  type="name"
                  name="firstName"
                  placeholder="First name"
                  required
                />
                <input
                  type="name"
                  name="lastName"
                  placeholder="Last name"
                  required
                />
              </div>
              <div className="input-sections">
                <input type="email" name="email" placeholder="Email" required />
                <input type="tel" name="phone" placeholder="Phone number" />
              </div>
              <textarea
                name="message"
                id=""
                cols="30"
                rows="10"
                placeholder="Message"
                required
              ></textarea>
              <div className="btn-section">
                <button>Send</button>
              </div>
            </form>
          </div>
          <div className="phone card">
            <p>Or, if you prefer you can reach me by phone at:</p>
            <address>
              <a href="tel+15102890397">(510) 289-0397</a>
            </address>
          </div>
        </div>
        <div className="svg-bg">
          <SVGBG />
        </div>

      </Container>
      {/* <Image /> */}
    </section>
  </>
)

export default Contact

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 500;

  .card {
    border-radius: 4px;
    padding: 40px 20px;
    max-width: 650px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 26px 0px,
      rgba(0, 0, 0, 0.15) 0px 17px 17px 0px;

    h2 {
      text-align: center;
      margin: 40px auto 100px;
      color: #1f70f2;
      font-size: 3rem;
    }

    form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;

      max-width: 600px;
      margin: 0 auto;

      input {
        padding: 10px;
        border-radius: 4px;
        border: 2px solid #999;
        width: 100%;

        &:focus {
          border: 2px solid #1f70f2;
          outline: #1f70f2;
        }
      }

      textarea {
        padding: 10px;
        border-radius: 4px;
        border: 2px solid #999;
        &:focus {
          border: 2px solid #1f70f2;
          outline: #1f70f2;
        }
      }

      .input-sections {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin: 0 0 20px;
      }

      .btn-section {
        display: grid;
        place-items: center;

        button {
          width: 120px;
          border: none;
          border-radius: 2px;
          color: #fff;
          background: #1f70f2;
          padding: 10px 10px;
        }
      }
    }
  }

  .phone {
    margin: 40px 0 0 ;
    text-align: center;
    p {
      font-weight: 500;
    }
  }

  .svg-bg {
    display: contents;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`
