import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Navbar from "./navbar"
import Hamburger from "./hamburger"

const Header = ({ siteTitle }) => {
  return (
    <Hdr>
      <Link to="/">
        <div id="title-container">
          <h1>{siteTitle}</h1>
          <h4>Sunny Donaire MA, C.L.C. Somatic Life Coach</h4>
        </div>
      </Link>
      <div className="burger">
        <Hamburger />
      </div>
      <div id="nav-container">
        <Navbar />
      </div>
    </Hdr>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const Hdr = styled.header`
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  padding: 0 2%;
  height: 80px;
  background: #1f70f2;
  display: grid;
  grid-template-columns: 1fr 2fr;
  
  align-items: center;
  box-shadow: 0 0 15px;

  a {
    /* width: 450px; */
    color: #fff;
    text-decoration: none;

    #title-container {
      display: grid;
      grid-template-columns: 1fr;
      width: 420px;
      text-align: center;

      h1 {
        width: 520px;
        display: grid;
        font-size: 2.2rem;
        margin: 0;
        font-weight: 350;
      }
      h4 {
        margin: 3px 0 0;
        font-weight: 350;
      }

      @media screen and (max-width: 1635px) {
        padding-top: 1%;
        text-align: center;
        h1 {
          width: 100%;
          font-size: 1.5rem;
        }
        h4 {
          font-size: 0.8rem;
        }
      }

      @media screen and (max-width: 650px) {
        width: 300px;
        h1 {
          font-size: 1.2rem;
        }
        h4 {
          margin: 8px 0 0;
          font-size: 0.675rem;
        }
      }
    }
  }

  
  .burger {
    display: none;
    @media screen and (max-width: 990px) {
      display: grid;
      width: auto;
    }
  }
`
