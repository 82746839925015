import React from "react"

import SVG2 from "../../assets/bg5-2.svg"

const SVG = () => {
  return (
    <>
      <SVG2 />
    </>
  )
}

export default SVG
