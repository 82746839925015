import React from "react"
import styled from "styled-components"

// import Layout from "../components/layout"
// import SEO from "../seo"

const ClientServices = () => {
  return (
    <>
      {/* <SEO title="Client Services" /> */}
      <Container
        id="Client Services"
      // style={{ minHeight: `100%`, marginBottom: `80px` }}
      >
        <div className="wrapper">
          <h2>Services Offered</h2>
          <div className="heading-bg">
            <h4>
              <span>LGBTQ</span>, Somatic Practice, Psychological studies,
              Multicultural Adaptation,
            </h4>
            <h4>Gender Identity and Expression</h4>
            <h4>All services offered in Tagolog & English</h4>
          </div>
          <div className="text-bg">
            <p>
              It is my privilege to accompany you in one-on-one on a journey to
              excavate and heal pain of body and mind, unlock potential, and
              build toward empowered, integrated embodiment. 
            </p>

            <p>
              Couples coaching creates a safe
              space in which to shine the light of awareness on unaddressed
              resentment, fear, and avoidance, clarify boundaries, needs and
              agreements, overcome painful patterns, build or rebuild trust, and
              co-create a vibrant new relationship vision.
            </p>

            <p>
              Family coaching creates
              a safe space to bring old and new wounds out into the light to be
              healed, untangle misunderstanding, break down and release
              judgments, and bridge differences. Every family is unique. Some
              families are better suited to group sessions. In other cases, a
              single family member hiring a coach may be more appropriate and
              can still have a powerful ripple effect on all parties involved.
              
            </p>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ClientServices

const Container = styled.section`
  height: fit-content;
  max-width: 960px;
  margin: 0 auto;
  padding: 140px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .heading-bg {
    background-color: #beb3dd55;
    padding: 40px;
    border-radius: 5px;
  }
  .text-bg {
    margin-top: 40px;
    background-color: #beb3dd55;
    padding: 40px;
    border-radius: 5px;
    p {
      font-weight: 500;
      letter-spacing: 1.2px;
      line-height: 30px;

      @media screen and (max-width: 800px) {
        text-align: justify;
      }
    }
  }

  .wrapper {
    margin: 0 4%;
    h2 {
      color: #1f70f2;
      font-size: 3rem;
      margin: 0 auto 100px;
      /* padding-top: 80px; */
      text-align: center;
    }

    h4 {
      text-align: center;
      font-size: 1.575rem;
    }
  }
`
