import React from 'react'
import SunriseVideo from '../../assets/lighthouse.mp4'

const CarouselVideo = () => {
  return (
    <>
      <video
        className="video-fluid"
        muted
        loop
        autoPlay
        playsInline
        // height="1000px"
        style={{
          // position: "absolute",
          display: `flex`,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          height: "93vh",
          width: "100%",
          zIndex: "-1",
          objectFit: "cover",
          objectPosition: "center",
        }}
      >
        <source src={SunriseVideo} type="video/mp4" />
      </video>
    </>
  )
}

export default CarouselVideo

// url to credit content creator.
// src="src/assets/sunrise.mp4"