import React from "react"
import styled from "styled-components"

// import SEO from "../seo"

const MyPractice = () => {
  return (
    <>
      {/* <SEO title="My Practice" /> */}
      <Container id="My Practice">
        <div className="wrapper">
          <h1 style={{ textAlign: `center` }}>My Practice</h1>
          <h2>Somatic Practice</h2>
          <p>
            Somatic methodology is a pathway to individual and collective
            transformation and integration , a holistic approach to creating
            healing shifts in embodied experience that nourish us over time and
            translate not only into new ways of being and perceiving, but into
            new depths of connection and empowered action.
          </p>

          <hr />

          <h2>Gender Identity</h2>

          <p>
            Gender Identity is the innermost concept of self as male, female, a
            blend of both, or neither , regardless of sex assigned at birth.
            Imposing rigid gender expectations on a baby can be damaging to the
            psycho-social development of the child from infancy to adulthood,
            restricting the degree to which they feel free to define, declare,
            and present gender as they experience it. Often erroneously
            discussed as a dysfunction, gender dysphoria may be experienced by
            anyone at any phase of life. Discarding the toxic messaging of
            socially-sanctioned, arbitrarily prescribed, gender norms in favor
            of authentic self-determination opens the way to harmonizing the
            inward and outward experience of gender, instead of suffering from
            dissonance between the two.
          </p>

          <hr />

          <h2>Multicultural Adaptation</h2>

          <p>
            Living in a new culture can be very confusing and difficult to
            process, especially when that culture is drastically different from
            your own. How does it feel not to be able to read street signs
            because they are written in an unfamiliar language? Or to have an
            outfit you are wearing deemed unacceptable by your peers? Building a
            strong foundational skillset with which to navigate the small and
            large, acute and persistent, stresses of cultural adaptation is not
            a quick and easy process. A person being introduced into a new
            culture may experience a variety of responses, including frustration
            at not being able to communicate and anger when confronted with
            cultural norms that seem to make no sense. For instance, in Filipino
            culture, burping after eating is a complement, while in certain
            other cultures it may be considered the opposite. These differences
            can be extremely uncomfortable! Cultural adaptation theory helps to
            contextualize and ground coaching work supporting you through these
            difficult emotions.
          </p>
        </div>
      </Container>
    </>
  )
}

export default MyPractice

const Container = styled.section`
  min-height: 100vh;
  max-width: 1120px;
  margin: 40px auto;
  padding-top: 140px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  .wrapper {
    margin: 0 4%;
    padding: 0;
  }

  h1 {
    color: #1f70f2;
    font-size: 3rem;
    margin: 0 auto 100px;
    text-align: center;
  }

  h2 {
    position: relative;
    width: fit-content;

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 8px;
      height: 20px;
      width: 105%;
      z-index: -10;
      background: #f7a49e55;
    }
  }

  p {
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 30px;
    text-align: justify;

    @media screen and (max-width: 800px) {
      letter-spacing: 1px;
      line-height: 32px;
    }
  }
`
