import React from "react"
import styled from "styled-components"

// import SEO from "../seo"
// import SVGB1 from "./svg-1"
import SVGB2 from "./svg-2"
import SVGB3 from "../../assets/bg5-1.svg"
import SVGB1 from '../../assets/yoga.svg'

const Referrals = () => {
  return (
    <>
      {/* <SEO title="Referrals" /> */}
      <Container id="Referrals">
        <div className="wrapper">
          <h2>Referrals</h2>
          <p>
            The confidentiality agreement helps keep an individual or business’s
            proprietary information confidential by preventing a recipient from
            disclosing such information to others.
          </p>
          <div>
            <SVGB2 />
            <p>
              The reason I would refer Sunny because he is first and foremost,
              compassionate. When speaking , feel free to talk about anything
              without feeling ashamed or feeling judged. I'm right at ease when
              we talk and I think that empathy is the most important quality for
              a life coach to have. It makes me feel safe and cared for. Anyone
              would be lucky to have Sunny as their life coach.- JG
            </p>
          </div>

          <div className="reverse">
            <p className="row1">
              Sunny’s sensitivity to his clients’ diverse needs, and
              skillfulness in supporting and co-creatively journeying
              with—rather than directing—them, allows clients to tap into the
              wellspring of their own internal resources while simultaneously
              acquiring new, transformational skills with which to overcome life
              challenges and achieve personal, professional, and relationship
              goals.- A.C.
            </p>
            <SVGB3 className="row2" />
          </div>

          <div>
            <SVGB1 />
            <p>
              Sunny is distinguished by his warmth of heart, generosity of
              spirit, non-binary view of life and individual experience,
              commitment to authenticity, multiculturalism, and to social
              justice-fighting racism, homophobia, trans-phobia, able-ism, and
              all forms of prejudice born of systemic oppression. Sunny is the
              real deal, with a wisdom born of transformed pain that you can't
              fake. His strength as a healer is deepened by a willingness to be
              vulnerable, his technical skills set balanced by a fearlessness to
              go with you to the heart of your deepest suffering, and then
              travel outward with you, at your own pace, and on your own terms,
              into a new expansiveness and celebration of life.- Hyejo K.
              Somaticdream
            </p>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Referrals

const Container = styled.section`
  min-height: 100vh;
  max-width: 1120px;
  margin: 40px auto 80px;
  padding-top: 140px;

  .wrapper {
    margin: 0 4%;
    padding: 0;

    div {
      display: grid;
      grid-template-columns: 0.5fr 1.5fr;
      grid-gap: 20px;
      place-items: center;
      margin: 40px auto;
    }

    h2 {
      color: #1f70f2;
      font-size: 3rem;
      margin: 0 auto 100px;
      text-align: center;
    }

    .reverse {
      display: grid;
      grid-template-columns: 1.5fr 0.5fr;
      grid-gap: 20px;
      place-items: center;
    }

    p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 0.865rem;
      font-weight: 500;
      letter-spacing: 1.2px;
      line-height: 30px;
      text-align: justify;
    }

    @media screen and (max-width: 850px) {
      div {
        grid-template-columns: 1fr;
      }
      .reverse {
        grid-template-columns: 1fr;
        grid-auto-flow: dense;

        .row1 {
          grid-row: 1;
        }
        .row1 {
          grid-row: 2;
        }
      }
    }
  }
`
