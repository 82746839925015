import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

import Services from "../client-services/client-services"

const BackgroundSection = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "bg16.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.image.childImageSharp.fluid
      return (
        <BackgroundImage fluid={imageData}>
          <Services />
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  max-width: 960px;
  /* background-position: bottom center; */
  /* background-repeat: repeat-y; */
  background-size: cover;
  background: #6556af99;
  margin-bottom: 140px;
  padding: 0;

  /* padding-top: 120px; */
`
export default StyledBackgroundSection
