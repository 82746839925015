import React from "react"
// import styled from "styled-components"

import Layout from "../components/layout"
// import SEO from "../components/seo"
import Home from "../components/home/home"
import About from "../components/about/about"
import MyPractice from "../components/my-practice/my-practice"
import Referrals from "../components/refferals/referrals"
import ClientServices from "../components/client-services/background"
import Contact from "../components/contact/contact"

const IndexPage = () => (
  <Layout>
    {/* <SEO title="Home" /> */}

    <Home />
    <About />
    <MyPractice />
    <Referrals />
    <ClientServices />
    <Contact />
  </Layout>
)

export default IndexPage
