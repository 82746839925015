import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import styled from "styled-components"
import Img from "gatsby-image"

import VideoItem from './CarouselVideo'
// import SEO from "../seo"

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      sunset: file(relativePath: { eq: "valley.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bridge: file(relativePath: { eq: "bridge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      downtown: file(relativePath: { eq: "downtown.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sunriseMov: file(relativePath: { eq: "sunrise.mp4" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section id="Home">
      {/* <SEO title={} /> */}
      <Carousel fade={true}>
        <Carousel.Item interval={12000}>
          <Img
            style={{ margin: `0`, height: `93vh` }}
            className="d-block w-100"
            fluid={data.sunset.childImageSharp.fluid}
            alt="mountains"
          />
          <Carousel.Caption id="introTitle">
            <div>
              <h3>Welcome!</h3>
              <hr />
              <h3 >Discovering Wellness</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={12000}>
          <Img
            style={{ margin: `0`, height: `93vh` }}
            className="d-block w-100"
            fluid={data.bridge.childImageSharp.fluid}
            alt="bridge with purple sky"
          />
          <Carousel.Caption id="secTitle">
            <h3>However you define it!</h3>
            <p>Ate and Kuya, Usap po tayo!</p>
          </Carousel.Caption>
        </Carousel.Item >

        <Carousel.Item interval={12000}>
          <VideoItem />
          <Carousel.Caption>
            <h3>Free 30 min phone consultations are available.</h3>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>
    </Section>
  )
}

export default Home

const Section = styled.section`
  margin-top: 80px;
  height: 100vh;

  .carousel-caption {
    bottom: 32%;
  }

  .carousel-item {
    position: relative;
    h3 {
      text-align: center;
      padding-bottom: 120px;
      font-size: 4.785rem;
      font-weight: 600;
      letter-spacing: 4.2px;
      font-family: 'Bilbo Swash Caps', cursive;
      font-weight: 400;
    }

    #introTitle {
      bottom: 29%;

      
      div {
        font-size: 5.785rem;
        h3 {
          padding-bottom: 0;
        }
        hr {
          background: #fff;
          width: 50%;
          margin: 25px auto;
        }
      }
    }

    #secTitle {
      h3 {
      font-size: 5.785rem;
      padding-bottom: 120px;
      }

      p {
        font-size: 3.875rem;
      }
    }

    p {
      font-size: 2.875rem;   
      font-family: 'Bilbo Swash Caps', cursive;
    }

    &:after {
      content:"";
      display:block;
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      background:rgba(0,0,0,0.4);      
    }
  }

  @media screen and (max-width: 1106px) {
    .carousel-item {

      #introTitle {
        font-size: 3.785rem;
      }

      #secTitle {
        h3 {
          font-size: 3.785rem;
        }
        p {
          font-size: 2.3rem;

        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .carousel-item {
      h3 {
        text-align: inherit;
        font-size: 2.3rem;
        padding-bottom: 80px;
      }
      #secTitle {  
          h3 {
            padding-bottom: 0;
          }
      }
    }
    .carousel-caption {
      bottom: 15%;
    }
  }
`
