import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const BurgerMenu = ({ open, setOpen }) => {
  return (
    <>
      {open ? (
        <Container>
          <ul>
            <li>
              <button onClick={() => setOpen(false)}>
                <Link to="#Home">Home</Link>
              </button>
            </li>
            <li>
              <button onClick={() => setOpen(false)}>
                <Link to="#About">About</Link>
              </button>
            </li>
            <li>
              <button onClick={() => setOpen(false)}>
                <Link to="#My Practice">My Practice</Link>
              </button>
            </li>
            <li>
              <button onClick={() => setOpen(false)}>
                <Link to="#Referrals">Referrals</Link>
              </button>
            </li>
            <li>
              <button onClick={() => setOpen(false)}>
                <Link to="#Client Services">Client Services</Link>
              </button>
            </li>
            <li>
              <button onClick={() => setOpen(false)}>
                <Link to="#Contact">Contact</Link>
              </button>
            </li>
          </ul>
        </Container>
      ) : null}
    </>
  )
}

export default BurgerMenu

const Container = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow-y: scroll;
  background: dodgerblue;

  ul {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;

    li {
      margin: 20px 0;
      height: 50px;
      list-style: none;
      font-size: 2rem;
    }

    button {
      background: inherit;
      border: none;
    }
  }
`
