import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Navbar = () => {
  return (
    <Container>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="#About">About</Link>
        </li>
        <li>
          <Link to="#My Practice">My Practice</Link>
        </li>
        <li>
          <Link to="#Referrals">Referrals</Link>
        </li>
        <li>
          <Link to="#Client Services">Client Services</Link>
        </li>
        <li>
          <Link to="#Contact">Contact</Link>
        </li>
      </ul>
    </Container>
  )
}

export default Navbar

const Container = styled.div`
  outline: transparent;
  ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    list-style: none;
    li {
      margin: 0;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 1635px) {
    font-size: 0.875rem;
    ul {
      /* width: 80%; */
    }
  }
  @media screen and (max-width: 1120px) {
    font-size: 0.875rem;
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
`
